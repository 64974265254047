export enum ComparisonType {
  IS_SAME = 'IS_SAME',
  IS_AFTER = 'IS_AFTER',
  IS_SAME_OR_AFTER = 'IS_SAME_OR_AFTER',
  IS_BEFORE = 'IS_BEFORE',
  IS_SAME_OR_BEFORE = 'IS_SAME_OR_BEFORE',
}

export enum DATE_TIME_FORMATS {
  HOURS_AND_MINUTES = 'HH:mm',
  FULL_YEAR = 'YYYY',
  TWO_DIGIT_YEAR = 'YY',
  ABBREVIATED_MONTH_TWO_DIGIT_YEAR = 'MMM YY',
  MONTH_NUM = 'M',
  LOCAL_DATE_TO_STRING = 'YYYY-MM-DD',
  PERIOD_SELECTOR_FORMAT = 'DD/MM/YYYY',
  COUNTER_FORMAT = 'HH:mm:ss',
  /**
   * Localized formats
   * Source: https://day.js.org/docs/en/display/format#list-of-localized-formats
   | Format | English Locale            | Sample Output                     |
   |--------|---------------------------|-----------------------------------|
   | LT     | h:mm A                    | 8:02 PM                           |
   | LTS    | h:mm:ss A                 | 8:02:18 PM                        |
   | L      | MM/DD/YYYY                | 08/16/2018                        |
   | LL     | MMMM D, YYYY              | August 16, 2018                   |
   | LLL    | MMMM D, YYYY h:mm A       | August 16, 2018 8:02 PM           |
   | LLLL   | dddd, MMMM D, YYYY h:mm A | Thursday, August 16, 2018 8:02 PM |
   | l      | M/D/YYYY                  | 8/16/2018                         |
   | ll     | MMM D, YYYY               | Aug 16, 2018                      |
   | lll    | MMM D, YYYY h:mm A        | Aug 16, 2018 8:02 PM              |
   | llll   | ddd, MMM D, YYYY h:mm A   | Thu, Aug 16, 2018 8:02 PM         |
   */
  LOCAL_DATE = 'L',
  LOCAL_DATE_PRETTY_ABBR = 'll',
  LOCAL_DATE_TIME = 'L LT',
  LOCAL_DATE_TIME_PRETTY = 'LLL',
  LOCAL_DATE_TIME_WITH_DAY_PRETTY_ABBR = 'llll',
  LOCAL_TIME = 'LT',
  // date-nfs localized formats
  'P_p' = 'P - p',
}
