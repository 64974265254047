import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material'

export const InfiniteScrollLoader = () => {
  return (
    <Table id='infinite-scroll-loader' style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell align='right'>
            <Skeleton />
          </TableCell>
          <TableCell align='right'>
            <Skeleton />
          </TableCell>
          <TableCell align='right'>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
