import { Skeleton } from '@mui/material'
import { FoxTextBody1 } from 'shared/src/components/typography/FoxText'
import { composeClassName } from 'shared/src/util/stringUtil'
import { isValue } from 'shared/src/util/typeGuard'
import 'whats_inside/src/pages/deviceData/components/dataElement/DataElement.scss'

type Props = {
  emphasisedContent?: string
  regularContent?: string
  className?: string
  isLoading?: boolean
  invert?: boolean
  align?: 'left' | 'right' | 'center'
  sameSizePartials?: boolean
}

export const DataElement = ({
  emphasisedContent,
  regularContent,
  className,
  isLoading = false,
  invert = false,
  align = 'center',
  sameSizePartials = false,
}: Props) => {
  return (
    <div
      className={composeClassName(
        'DataElement',
        className,
        invert ? 'invert' : '',
        sameSizePartials ? 'equal-size' : '',
      )}
      style={{ textAlign: align }}
    >
      <FoxTextBody1>{isLoading && !isValue(regularContent) ? <Skeleton /> : regularContent}</FoxTextBody1>
      <FoxTextBody1 bold>{isLoading && !isValue(emphasisedContent) ? <Skeleton /> : emphasisedContent}</FoxTextBody1>
    </div>
  )
}
