import { FoxIconCaution } from 'shared/src/components/font/FoxIcon'
import { FoxTextBody1, FoxTextH2 } from 'shared/src/components/typography/FoxText'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import { useDeviceId } from 'whats_inside/src/hooks/useDeviceId'

export const NoData = () => {
  const deviceId = useDeviceId()

  return (
    <section className='error'>
      <div className='icon-wrapper'>
        <FoxIconCaution />
      </div>
      <FoxTextH2>{isValue(deviceId) ? T('data_fetching_issues') : T('device_id_missing')}</FoxTextH2>
      {isValue(deviceId) ? <FoxTextBody1 bold>{T('try_again')}</FoxTextBody1> : null}
    </section>
  )
}
