import { useQuery } from '@tanstack/react-query'
import { isValue } from 'shared/src/util/typeGuard'
import { httpClient } from 'whats_inside/src/service/HttpClient'

export const useQueryGetDeviceData = (deviceId: string | undefined) => {
  return useQuery({
    queryKey: httpClient.queryKeys.deviceData(deviceId),
    queryFn: async () => {
      if (!isValue(deviceId)) return undefined
      const response = await httpClient.getDeviceData(deviceId)
      return response.data
    },
    placeholderData: (previousData) => previousData,
    enabled: isValue(deviceId),
  })
}
