import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'

export const MeteringDataSkeleton = () => {
  return (
    <TableBody>
      {Array(3)
        .fill(0)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index}>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell align='right'>
              <Skeleton />
            </TableCell>
            <TableCell align='right'>
              <Skeleton />
            </TableCell>
            <TableCell align='right'>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  )
}
