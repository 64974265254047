import { Fab } from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import { FoxIconArrowUp } from 'shared/src/components/font/FoxIcon'
import { composeClassName } from 'shared/src/util/stringUtil'
import 'shared/src/components/scrollToTop/ScrollToTopFab.scss'

const visibilityOffsetToggle = 300

export const ScrollToTopFab = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useLayoutEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY >= visibilityOffsetToggle) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <Fab
      className={composeClassName('scroll-to-top-fab', isVisible ? 'visible' : '')}
      color='primary'
      aria-label='scroll-to-top'
      onClick={handleClick}
    >
      <FoxIconArrowUp />
    </Fab>
  )
}
