import { useEffect, useLayoutEffect, useState } from 'react'
import { FoxFooter } from 'shared/src/components/foxFooter/FoxFooter'
import { ScrollToTopFab } from 'shared/src/components/scrollToTop/ScrollToTopFab'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import { composeClassName } from 'shared/src/util/stringUtil'
import { isValue } from 'shared/src/util/typeGuard'
import { useDeviceId } from 'whats_inside/src/hooks/useDeviceId'
import { DeviceDataContent } from 'whats_inside/src/pages/deviceData/components/dataContent/DeviceDataContent'
import { NoData } from 'whats_inside/src/pages/deviceData/components/errorHandling/NoData'
import { DeviceMeteringsDataRowHeight, MeteringDataSectionId } from 'whats_inside/src/pages/deviceData/util'
import { useQueryGetDeviceData } from 'whats_inside/src/queries/useQueryGetDeviceData'
import { DefaultMeteringsCount, useQueryGetDeviceMeterings } from 'whats_inside/src/queries/useQueryGetDeviceMeterings'
import 'whats_inside/src/pages/deviceData/DeviceData.scss'

export const DeviceData = () => {
  const [count, setCount] = useState<number | undefined>(undefined)
  const deviceId = useDeviceId()
  const {
    data: deviceData,
    isError: isDeviceDataError,
    isInitialLoading: isInitialLoadingDeviceData,
  } = useQueryGetDeviceData(deviceId)
  const {
    data: meteringData,
    hasNextPage,
    isInitialLoading: isInitialLoadingDeviceMeteringData,
    isError: isDeviceMeteringDataError,
    isFetchingNextPage,
    fetchNextPage,
  } = useQueryGetDeviceMeterings({ deviceId, count })

  const { AnalyticsEvent } = useAnalytics()
  useEffect(() => {
    if (isValue(deviceId)) {
      AnalyticsEvent('Page visit', deviceId)
    } else {
      AnalyticsEvent('Page visit: Invalid ID', deviceId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    const wrapperHeight = document.querySelector('.device-data-wrapper')?.clientHeight ?? 0
    const sectionsWithoutMeteringDataHeight = Array.from(
      document.querySelectorAll(`.device-data-wrapper section:not(#${MeteringDataSectionId})`),
    ).reduce((acc, curr) => acc + curr.clientHeight, 0)

    const meteringDataSpace = wrapperHeight - sectionsWithoutMeteringDataHeight

    if (meteringDataSpace > 0) {
      const calculatedCount = Math.round(meteringDataSpace / DeviceMeteringsDataRowHeight)
      setCount(Math.max(DefaultMeteringsCount, calculatedCount))
    } else {
      setCount(DefaultMeteringsCount)
    }
  }, [])

  return (
    <div className='app'>
      <div
        className={composeClassName(
          'device-data-wrapper',
          !isValue(deviceId) || isDeviceMeteringDataError || isDeviceDataError ? 'error' : '',
        )}
      >
        {isValue(deviceId) && !isDeviceMeteringDataError && !isDeviceDataError ? (
          <DeviceDataContent
            deviceData={deviceData}
            meteringData={meteringData}
            hasNextPage={hasNextPage ?? false}
            isLoadingDeviceData={isInitialLoadingDeviceData}
            isLoadingDeviceMeteringData={isInitialLoadingDeviceMeteringData}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />
        ) : (
          <NoData />
        )}
      </div>
      <FoxFooter />
      <ScrollToTopFab />
    </div>
  )
}
