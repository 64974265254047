import { useInfiniteQuery } from '@tanstack/react-query'
import { isValue } from 'shared/src/util/typeGuard'
import { httpClient } from 'whats_inside/src/service/HttpClient'

type Props = {
  deviceId: string | undefined
  count?: number
}

export const DefaultMeteringsCount = 10

export const useQueryGetDeviceMeterings = ({ deviceId, count }: Props) => {
  return useInfiniteQuery({
    queryKey: httpClient.queryKeys.deviceMeteringData(deviceId),
    queryFn: async ({ pageParam = 0 }) => {
      if (!isValue(deviceId) || !isValue(count)) return undefined
      const response = await httpClient.getDeviceMeteringData(deviceId, count, Number(pageParam))
      return response.data
    },
    enabled: isValue(deviceId) && isValue(count),
    initialPageParam: 0,
    getNextPageParam: (lastResponse) => {
      if (isValue(lastResponse) && isValue(count) && count + lastResponse.offset < lastResponse.total) {
        return lastResponse.offset + count
      }
      return undefined
    },
  })
}
