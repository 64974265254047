import * as Sentry from '@sentry/react'
import { WrapperProvider } from 'shared/src/context/WrapperProvider'
import { ANALYTICS_CATEGORY } from 'shared/src/util/trackingHelpers'
import { DeviceData } from 'whats_inside/src/pages/deviceData'

const App = () => {
  return (
    <WrapperProvider analyticsCategory={ANALYTICS_CATEGORY.WHATS_INSIDE}>
      <DeviceData />
    </WrapperProvider>
  )
}
export default Sentry.withProfiler(App)
