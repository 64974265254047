import { Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import type { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/query-core'
import { Fragment, useLayoutEffect } from 'react'
import type { DtoOffsetResult } from 'shared/src/model/common'
import { Unit, VolumeDisplayType } from 'shared/src/model/common'
import { DateTime } from 'shared/src/util/dateTime'
import { DATE_TIME_FORMATS } from 'shared/src/util/dateTime/constants'
import { isElementInViewPort } from 'shared/src/util/domUtil'
import { composeClassName } from 'shared/src/util/stringUtil'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import type { DtoInsideDevice, DtoInsideDeviceMetering } from 'whats_inside/src/model'
import { DataElement } from 'whats_inside/src/pages/deviceData/components/dataElement/DataElement'
import { NextExpectedMetering } from 'whats_inside/src/pages/deviceData/components/nextExpectedMetering/NextExpectedMetering'
import { RadialBar } from 'whats_inside/src/pages/deviceData/components/radialBar/RadialBar'
import { DeviceDataSkeleton } from 'whats_inside/src/pages/deviceData/components/skeletons/DeviceDataSkeleton'
import { InfiniteScrollLoader } from 'whats_inside/src/pages/deviceData/components/skeletons/InfiniteScrollLoader'
import { MeteringDataSkeleton } from 'whats_inside/src/pages/deviceData/components/skeletons/MeteringDataSkeleton'
import { DeviceMeteringsDataRowHeight, MeteringDataSectionId } from 'whats_inside/src/pages/deviceData/util'
import { isErroredMeteringValueInvalid } from 'whats_inside/src/util/meteringValidation'

type Props = {
  deviceData: DtoInsideDevice | undefined
  meteringData: InfiniteData<DtoOffsetResult<DtoInsideDeviceMetering> | undefined> | undefined
  hasNextPage: boolean
  isLoadingDeviceData: boolean
  isLoadingDeviceMeteringData: boolean
  isFetchingNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<
    InfiniteQueryObserverResult<InfiniteData<DtoOffsetResult<DtoInsideDeviceMetering> | undefined, unknown>, Error>
  >
}

export const DeviceDataContent = ({
  deviceData,
  meteringData,
  hasNextPage,
  isLoadingDeviceData,
  isLoadingDeviceMeteringData,
  isFetchingNextPage,
  fetchNextPage,
}: Props) => {
  const unit = deviceData?.computed.totalQuantity.unit ?? Unit.LITER

  useLayoutEffect(() => {
    const scrollListener = () => {
      if (isElementInViewPort('infinite-scroll-loader') && !isFetchingNextPage) fetchNextPage()
    }

    document.addEventListener('scroll', scrollListener)
    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [fetchNextPage, isFetchingNextPage])

  const numOfPages = meteringData?.pages.length ?? 0
  const firstPageMeteringLength = meteringData?.pages[0]?.data.length ?? 0
  const hasNoMeterings = numOfPages === 0 || (numOfPages === 1 && firstPageMeteringLength === 0)

  return (
    <>
      {!isLoadingDeviceData && isValue(deviceData) ? (
        <>
          <section data-cy='gauge-section' className='gauge-section'>
            <DataElement
              className='main'
              emphasisedContent={deviceData.componentName}
              regularContent={deviceData.hwid}
              invert
            />
            <RadialBar percentage={deviceData.lastMetering.fillingPercentage} />
          </section>
          <section data-cy='device-data-section' className='device-data-section'>
            <DataElement
              emphasisedContent={`~ ${deviceData.lastMetering.quantity.value} / ${
                deviceData.computed.displayAs100Percent === VolumeDisplayType.TOTAL_VOLUME
                  ? deviceData.computed.totalQuantity.value
                  : deviceData.computed.usableQuantity.value
              } ${unit} (${T(`volume_display_type_wi_${deviceData.computed.displayAs100Percent}`)})`}
              regularContent={T('current_fill_level')}
            />
            <DataElement
              emphasisedContent={`~ ${
                deviceData.computed.usableQuantity.value - deviceData.lastMetering.quantity.value
              } ${unit}`}
              regularContent={T('freeUsableQuantity')}
            />

            <DataElement emphasisedContent={deviceData.computed.productName} regularContent={T('product')} />
          </section>
          <section>
            <Divider />
          </section>
          <section data-cy='last-next-metering-section' className='last-next-metering-section'>
            <Grid container>
              <Grid item xs={6}>
                <DataElement
                  sameSizePartials
                  emphasisedContent={DateTime.format(
                    deviceData.lastMetering.createdAt,
                    DATE_TIME_FORMATS.LOCAL_DATE_TIME_PRETTY,
                  )}
                  regularContent={T('lastMeasurement')}
                  align='right'
                />
              </Grid>
              <Grid item xs={6}>
                <NextExpectedMetering nextExpectedMeteringAt={deviceData.lastMetering.nextExpectedMeteringAt} />
              </Grid>
            </Grid>
          </section>
          <section>
            <Divider />
          </section>
        </>
      ) : (
        <DeviceDataSkeleton />
      )}

      <section id={MeteringDataSectionId} data-cy={MeteringDataSectionId} className='metering-data-section'>
        <DataElement sameSizePartials emphasisedContent={T('lastMeasurements')} regularContent='' align='left' />
        <Table aria-label='measurements-table'>
          <TableHead>
            <TableRow style={{ height: DeviceMeteringsDataRowHeight }}>
              <TableCell width={90} title={`${T('date')} / ${T('time')}`}>{`${T('date')} / ${T('time')}`}</TableCell>
              <TableCell align='right' title={`${T('volume_without_unit')} ${unit}`}>
                {T('volume_without_unit')} <br /> {unit}
              </TableCell>
              <TableCell align='right' title={`${T('fillLevelHeight')} ${Unit.MILLIMETER}`}>
                {T('fillLevelHeight')} <br /> {Unit.MILLIMETER}
              </TableCell>
              <TableCell align='right' title={`${T('distance')} ${Unit.MILLIMETER}`}>
                {T('distance')} <br /> {Unit.MILLIMETER}
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoadingDeviceMeteringData && isValue(meteringData) ? (
            <TableBody>
              {hasNoMeterings ? (
                <TableRow>
                  <TableCell colSpan={4} data-cy='no-data-row'>
                    {T('no_data')}
                  </TableCell>
                </TableRow>
              ) : (
                meteringData.pages.map((page) => {
                  if (!isValue(page) || page.data.length === 0) return null
                  return (
                    <Fragment key={`fr-${page?.data[0].id}`}>
                      {page?.data.map((it) => (
                        <TableRow
                          style={{ height: DeviceMeteringsDataRowHeight }}
                          key={it.id}
                          className={composeClassName(
                            isErroredMeteringValueInvalid(it.validationError) || it.isManuallyInvalidated
                              ? 'invalid'
                              : '',
                          )}
                        >
                          <TableCell>{DateTime.format(it.createdAt, DATE_TIME_FORMATS.LOCAL_DATE_TIME)}</TableCell>
                          <TableCell align='right'>
                            {isErroredMeteringValueInvalid(it.validationError) ? T('invalid') : it.quantity.value}
                          </TableCell>
                          <TableCell align='right'>
                            {isErroredMeteringValueInvalid(it.validationError) ? T('invalid') : it.fillHeightMm}
                          </TableCell>
                          <TableCell align='right'>
                            {isErroredMeteringValueInvalid(it.validationError)
                              ? T('invalid')
                              : it.normalizedDistanceMm ?? '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  )
                })
              )}
            </TableBody>
          ) : (
            <MeteringDataSkeleton />
          )}
        </Table>
        {!isLoadingDeviceMeteringData && isValue(meteringData) && hasNextPage ? <InfiniteScrollLoader /> : null}
      </section>
    </>
  )
}
