import { Grid } from '@mui/material'
import { T } from 'shared/src/util/translation'
import { DataElement } from 'whats_inside/src/pages/deviceData/components/dataElement/DataElement'
import { NextExpectedMetering } from 'whats_inside/src/pages/deviceData/components/nextExpectedMetering/NextExpectedMetering'
import { RadialBar } from 'whats_inside/src/pages/deviceData/components/radialBar/RadialBar'

export const DeviceDataSkeleton = () => {
  return (
    <>
      <section className='gauge-section'>
        <DataElement isLoading className='main' invert />
        <RadialBar isLoading percentage={0} />
      </section>
      <section className='device-data-section'>
        <DataElement isLoading regularContent={T('current_fill_level')} />
        <DataElement isLoading regularContent={T('freeUsableQuantity')} />
        <DataElement isLoading regularContent={T('product')} />
      </section>
      <section className='last-next-metering-section'>
        <Grid container>
          <Grid item xs={5}>
            <DataElement isLoading sameSizePartials regularContent={T('lastMeasurement')} align='right' />
          </Grid>
          <Grid item xs={5}>
            <NextExpectedMetering isLoading nextExpectedMeteringAt='' />
          </Grid>
        </Grid>
      </section>
    </>
  )
}
