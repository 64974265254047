import { isValue } from 'shared/src/util/typeGuard'

export const isElementInViewPort = (elementId: string): boolean => {
  const loader = document.getElementById(elementId)

  if (!isValue(loader)) return false
  const bounding = loader.getBoundingClientRect()

  return (
    bounding.top >= bounding.top - loader.offsetHeight &&
    bounding.left >= bounding.left - loader.offsetWidth &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + loader.offsetWidth &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + loader.offsetHeight
  )
}
