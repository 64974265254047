import { createRoot } from 'react-dom/client'
import 'shared/src/styles/index.scss'
import { setFoxColors } from 'shared/src/theme/foxColor'
import { initializeDayJS } from 'shared/src/util/dateTime/dayjs'
import { Secrets } from 'shared/src/util/environment'
import { initializeSentry, initializeSimpleAnalytics } from 'shared/src/util/trackingHelpers'
import App from 'whats_inside/src/App'

setFoxColors()
initializeDayJS()
initializeSentry(Secrets.sentry.whatsInsideDSN)
initializeSimpleAnalytics()

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(<App />)
