import type { AxiosResponse } from 'axios'
import type { DtoOffsetResult } from 'shared/src/model/common'
import { BaseHttpClient } from 'shared/src/service/http/BaseHttpClient'
import type { DtoInsideDevice, DtoInsideDeviceMetering } from 'whats_inside/src/model'

class HttpClient extends BaseHttpClient {
  queryKeys = {
    deviceData: (id: string | undefined) => ['DeviceData', id],
    deviceMeteringData: (id: string | undefined) => ['DeviceMetering', id],
  }

  getDeviceData(externalId: string): Promise<AxiosResponse<DtoInsideDevice>> {
    return this.client.get<DtoInsideDevice>(`/inside/${externalId}`)
  }

  getDeviceMeteringData(
    externalId: string,
    count: number,
    offset: number,
  ): Promise<AxiosResponse<DtoOffsetResult<DtoInsideDeviceMetering>>> {
    return this.client.get<DtoOffsetResult<DtoInsideDeviceMetering>>(
      `/inside/${externalId}/metering?count=${count}&offset=${offset}`,
    )
  }
}

export const httpClient = new HttpClient()
