import { useLayoutEffect, useState } from 'react'
import { DateTime } from 'shared/src/util/dateTime'
import { ComparisonType } from 'shared/src/util/dateTime/constants'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import { DataElement } from 'whats_inside/src/pages/deviceData/components/dataElement/DataElement'

type Props = {
  nextExpectedMeteringAt?: string
  isLoading?: boolean
}

export const NextExpectedMetering = ({ nextExpectedMeteringAt, isLoading = false }: Props) => {
  const [nextExpectedMeteringDuration, setNextExpectedMeteringDuration] = useState(
    isValue(nextExpectedMeteringAt)
      ? DateTime.durationBetweenDates(new Date(), new Date(nextExpectedMeteringAt))
      : undefined,
  )

  useLayoutEffect(() => {
    let interval: NodeJS.Timeout
    if (!isLoading && isValue(nextExpectedMeteringAt)) {
      interval = setInterval(() => {
        setNextExpectedMeteringDuration(DateTime.durationBetweenDates(new Date(), new Date(nextExpectedMeteringAt)))
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isLoading, nextExpectedMeteringAt])

  let emphasisedContent = T('unknown')

  if (isValue(nextExpectedMeteringAt)) {
    emphasisedContent = DateTime.compare({
      date1: DateTime.date(),
      date2: DateTime.date(nextExpectedMeteringAt),
      comparisonType: ComparisonType.IS_AFTER,
      unit: 'ms',
    })
      ? T('pending')
      : DateTime.humanizedDuration({
          value: nextExpectedMeteringDuration ?? 0,
          hasSuffix: true,
        })
  }

  return (
    <DataElement
      isLoading={isLoading}
      sameSizePartials
      emphasisedContent={isLoading ? undefined : emphasisedContent}
      regularContent={T('nextMeasurement')}
      align='left'
    />
  )
}
