import { validate } from 'uuid'
import { Regex } from 'shared/src/util/regex'
import { isValue } from 'shared/src/util/typeGuard'

export const isHWID = (id: string | undefined): id is string => {
  return isValue(id) && (Regex.hwid.test(id) || Regex.hwidDemo.test(id))
}

export const isUUID = (id: string | undefined): id is string => {
  return isValue(id) && validate(id)
}
