import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { DATE_TIME_FORMATS } from 'shared/src/util/dateTime/constants'
import { ComparisonType } from 'shared/src/util/dateTime/constants'
import type { CompareArgs, HumanizedDurationArgs } from 'shared/src/util/dateTime/types'
import { getLanguageConfig, Locale, localeToLangCodeMap } from 'shared/src/util/language'
import { isValue } from 'shared/src/util/typeGuard'

export class DateTime {
  static MINUTES_IN_HOUR = 60
  static MS_IN_A_DAY = 86400000

  static format(date: string | Date | Dayjs | number | undefined, timeFormat?: DATE_TIME_FORMATS): string {
    return dayjs(date).locale(this.getDayjsLocaleKey()).format(timeFormat)
  }

  static date(value?: string | number | Date | Dayjs | undefined): Dayjs {
    return dayjs(value).locale(this.getDayjsLocaleKey())
  }

  static getDayjsLocaleKey(): string {
    const { locale: currentLocale } = getLanguageConfig()
    return currentLocale === Locale.ENGLISH
      ? currentLocale.toLowerCase()
      : localeToLangCodeMap[currentLocale].toLowerCase()
  }

  static humanizedDuration(args: HumanizedDurationArgs): string {
    const { value, unit, hasSuffix } = args
    return this.duration(value, unit).locale(this.getDayjsLocaleKey()).humanize(hasSuffix)
  }

  static duration(time: number, unit?: plugin.DurationUnitType | undefined): plugin.Duration {
    return dayjs.duration(time, unit)
  }

  static durationBetweenDates(date1: Date, date2: Date, unit?: plugin.DurationUnitType | undefined): number {
    return this.diff(date2, date1, unit)
  }

  static diff(date1: string | Date, date2: string | Date, unit?: plugin.DurationUnitType) {
    const d1 = dayjs(date1)
    const d2 = isValue(date2) ? dayjs(date2) : dayjs()
    return d1.diff(d2, unit ?? 'ms')
  }

  static compare(args: CompareArgs): boolean {
    const { date1, comparisonType, unit } = args
    let { date2 } = args
    if (!isValue(date2)) {
      date2 = dayjs()
    }

    switch (comparisonType) {
      case ComparisonType.IS_SAME:
        return date1.isSame(date2, unit)
      case ComparisonType.IS_AFTER:
        return date1.isAfter(date2, unit)
      case ComparisonType.IS_SAME_OR_AFTER:
        return date1.isSameOrAfter(date2, unit)
      case ComparisonType.IS_BEFORE:
        return date1.isBefore(date2, unit)
      case ComparisonType.IS_SAME_OR_BEFORE:
        return date1.isSameOrBefore(date2, unit)
      default:
        return false
    }
  }
}
