import { ReactComponent as MadeByFoxInsightsLogo } from 'shared/src/img/madeby-foxinsights.svg'
import 'shared/src/components/foxFooter/FoxFooter.scss'

export const FoxFooter = () => {
  return (
    <div className='fox-footer'>
      <MadeByFoxInsightsLogo />
    </div>
  )
}
