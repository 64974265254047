import { Skeleton } from '@mui/material'
import { useLayoutEffect } from 'react'
import { FoxColor } from 'shared/src/theme/foxColor'
import { composeClassName } from 'shared/src/util/stringUtil'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import 'whats_inside/src/pages/deviceData/components/radialBar/RadialBar.scss'

type Props = {
  percentage: number
  isLoading?: boolean
}

const degToRad = (deg: number) => {
  return (deg * Math.PI) / 180
}

const setupCanvas = (canvas: HTMLCanvasElement) => {
  const dpr = window.devicePixelRatio || 1
  const rect = canvas.getBoundingClientRect()
  // eslint-disable-next-line no-param-reassign
  canvas.width = rect.width * dpr
  // eslint-disable-next-line no-param-reassign
  canvas.height = rect.height * dpr
  const ctx = canvas.getContext('2d')
  if (isValue(ctx)) ctx.scale(dpr, dpr)
  return ctx
}

const getGradientColors = (percentage: number) => {
  if (percentage < 25) {
    return {
      from: FoxColor.red200,
      to: FoxColor.red600,
    }
  }
  if (percentage < 70) {
    return {
      from: FoxColor.orangy200,
      to: FoxColor.orangy600,
    }
  }
  return {
    from: FoxColor.green200,
    to: FoxColor.green600,
  }
}

export const RadialBar = ({ percentage, isLoading = false }: Props) => {
  useLayoutEffect(() => {
    if (!isLoading) {
      const canvas = document.getElementById('gauge-canvas') as HTMLCanvasElement
      const canvasWrapper = canvas.parentElement
      const ctx = setupCanvas(canvas)

      if (isValue(ctx) && isValue(canvasWrapper)) {
        ctx.beginPath()
        // default start is at 90 deg
        const startAngle = degToRad(0 - 90)
        const percentageDeg = percentage % 100 === percentage ? percentage : 100

        if (percentageDeg > 0) {
          const endAngle = degToRad((360 * percentageDeg) / 100) - degToRad(90)
          const radius = 90
          const center = {
            x: canvasWrapper.clientWidth / 2,
            y: canvasWrapper.clientWidth / 2,
          }
          ctx.arc(center.x, center.y, radius, startAngle, endAngle)
          const gradient = ctx.createConicGradient(startAngle, center.x, center.y)
          const colors = getGradientColors(percentage)
          gradient.addColorStop(1, colors.from)
          gradient.addColorStop((percentageDeg - 0.15) / 100, colors.to)
          gradient.addColorStop(0, colors.from)
          ctx.strokeStyle = gradient
          ctx.lineCap = 'round'
          ctx.lineWidth = 8
          ctx.stroke()
        }
      }
    }
  }, [isLoading, percentage])

  return (
    <div className={composeClassName('gauge-wrapper', isLoading ? 'loading' : '')}>
      <canvas id='gauge-canvas' />
      {isLoading ? (
        <>
          <Skeleton variant='circular' style={{ width: 200, height: 200 }} />
          <div className='loading-mask' />
        </>
      ) : (
        <>
          <p className='percentage-value'>{`${percentage} %`}</p>
          <p>{T('fill_level')}</p>
        </>
      )}
    </div>
  )
}
