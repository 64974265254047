export enum MeteringValidationError {
  NO_METERING = 'NO_METERING',
  EMPTY_METERING = 'EMPTY_METERING',
  NO_EXTRACTED_VALUE = 'NO_EXTRACTED_VALUE',
  SENSOR_CONFIG = 'SENSOR_CONFIG',
  MISSING_STORAGE_CONFIG = 'MISSING_STORAGE_CONFIG',
  INVALID_STORAGE_CONFIG = 'INVALID_STORAGE_CONFIG',
  DISTANCE_TOO_SHORT = 'DISTANCE_TOO_SHORT',
  ABOVE_STORAGE_MAX = 'ABOVE_STORAGE_MAX',
  BELOW_STORAGE_MIN = 'BELOW_STORAGE_MIN',
}

export enum SignalStrength {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  WEAK = 'WEAK',
  VERY_WEAK = 'VERY_WEAK',
}

export enum BatteryLevel {
  FULL = 'FULL',
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}
