import { MeteringValidationError } from 'shared/src/model/deviceModel'
import { isValue } from 'shared/src/util/typeGuard'

export const displayErrors = [
  MeteringValidationError.ABOVE_STORAGE_MAX,
  MeteringValidationError.DISTANCE_TOO_SHORT,
  MeteringValidationError.BELOW_STORAGE_MIN,
]

export const isErroredMeteringValueInvalid = (error: MeteringValidationError | undefined): boolean => {
  return isValue(error) && !displayErrors.includes(error)
}
